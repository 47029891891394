import React, { useState, useMemo } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import BlogCard from '../components/BlogCard';
import CtaNewsletter from '../components/CtaNewsletter';

// import logo from '../images/NineDotsLogo.svg';

import '../css/blog/blogpage.scss';

export default function BlogPage({ data, location }) {
  const allBlogs = data.allSanityBlogs.nodes;
  const { title, heroText, heroImg } = data.sanityBlogpage.heroSection;
  const { seo } = data.sanityBlogpage;

  const [filter, setFilter] = useState('');
  const filteredBlogs = useMemo(
    () =>
      filter ? allBlogs.filter((blog) => blog.blogTag === filter) : allBlogs,
    [filter, allBlogs]
  );

  return (
    <>
      <SEO
        title={seo.title || title}
        description={seo.description && seo.description}
        image={seo.ogImage && seo.ogImage.asset.url}
        location={location}
      />

      <Layout>
        <Hero
          tag="Blog"
          title={title}
          para={heroText}
          heroImg={heroImg.asset.url}
        />

        <div className="blog-filter">
          <div className="wrapper">
            <div className="filter-item">
              <img
                src="https://cdn.sanity.io/images/oxlo3vns/production/a4c2afa5092bbab4f8c4e96b6b1d81e2d04448b6-333x314.svg?w=333&h=314&auto=format"
                alt=""
              />
              <button
                onClick={() => setFilter('hiring')}
                type="button"
                className="button dark"
              >
                For the hiring manager
              </button>
            </div>
            <div className="filter-item">
              <img
                src="https://cdn.sanity.io/images/oxlo3vns/production/e91c96d5d3f430def844dcf90aaf7bdf712327e4-653x283.svg?w=653&h=283&auto=format"
                alt=""
              />
              <button
                onClick={() => setFilter('candidate')}
                type="button"
                className="button dark"
              >
                For the job seeker
              </button>
            </div>
            <div className="filter-item">
              <img
                src="https://cdn.sanity.io/images/oxlo3vns/production/7d00acd5fe03df832bf68a783530c42cf6fcc903-200x142.svg?w=200&h=142&auto=format"
                alt=""
              />
              <button
                onClick={() => setFilter('')}
                type="button"
                className="button dark"
              >
                View all resources
              </button>
            </div>
          </div>
        </div>

        <section id="blog-listing">
          <div className="wrapper">
            <div className="all-blog-cards">
              {filteredBlogs.map((blog, i) => (
                <BlogCard
                  key={i}
                  href={blog.slug.current}
                  title={blog.title}
                  author={blog.author}
                  date={blog.date}
                  image={blog.thumbnail.asset.gatsbyImageData}
                  alt={blog.thumbnail.alt}
                />
              ))}
            </div>
          </div>
        </section>

        <CtaNewsletter />
      </Layout>
    </>
  );
}

export const query = graphql`
  {
    sanityBlogpage {
      heroSection {
        title
        heroText
        heroImg {
          asset {
            gatsbyImageData
            url
          }
        }
      }
      seo {
        title
        description
        ogImage {
          asset {
            url
          }
        }
      }
    }
    allSanityBlogs(sort: { fields: date, order: DESC }) {
      nodes {
        title
        slug {
          current
        }
        thumbnail {
          alt
          asset {
            gatsbyImageData
          }
        }
        author
        date(formatString: "MMM D, YYYY")
        blogTag
      }
    }
  }
`;
