import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import Values from '../components/about/Values';
import MeetOurTeam from '../components/about/MeetOurTeam';
import ClientLogos from '../components/about/ClientLogos';
import CtaBox from '../components/CtaBox';

import '../css/about/aboutpage.scss';
import Principles from '../components/about/Principles';

export default function AboutPage({ data, location }) {
  const { title, heroText, heroImg } = data.sanityAboutpage.heroSection;
  const { summary, _rawValues, seo } = data.sanityAboutpage;

  return (
    <>
      <SEO
        title={seo.title || title}
        description={seo.description && seo.description}
        image={seo.ogImage && seo.ogImage.asset.url}
        location={location}
      />

      <Layout>
        <Hero
          tag="Who we are"
          title={title}
          para={heroText}
          heroImg={heroImg.asset.url}
        />
        <Values summary={summary} _rawValues={_rawValues} />
        <Principles />
        <MeetOurTeam />
        <ClientLogos />
        <section className="special-cta">
          <CtaBox />
        </section>
      </Layout>
    </>
  );
}

export const query = graphql`
  {
    sanityAboutpage {
      heroSection {
        title
        heroText
        heroImg {
          asset {
            gatsbyImageData
            url
          }
        }
      }
      summary
      _rawValues
      seo {
        title
        description
        ogImage {
          asset {
            url
          }
        }
      }
    }
  }
`;
