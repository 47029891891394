import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

import '../css/about/aboutpage.scss';

export default function PrivacyPolicyPage() {
  return (
    <>
      <SEO
        title="Privacy Policy"
        description="Ninedots privacy and cookie policies"
      />

      <Layout>
        <section className="blog-hero">
          <div className="wrapper centered">
            <div className="heading-content">
              <h1>Privacy Policy</h1>

              <p className="summary">
                nineDots.io Privacy Policies and Cookie Policies
              </p>
            </div>
          </div>
        </section>

        <section className="blog-template">
          <div className="wrapper centered">
            <div className="template-content">
              <h2>1. Information Collection</h2>
              <p>
                We collect and process personal information that is necessary
                for the provision of our recruitment services. This may include:
              </p>
              <ul>
                <li>Contact details (name, address, email, phone number)</li>
                <li>Employment history</li>
                <li>Educational background</li>
                <li>Skills and qualifications</li>
                <li>
                  Any other information relevant to job applications or
                  placements
                </li>
              </ul>

              <h2>2. Use of Information</h2>
              <p>
                We use the collected information for the following purposes:
              </p>
              <ul>
                <li>Matching your profile with available job opportunities</li>
                <li>Communicating with you about potential job placements</li>
                <li>Verifying your qualifications and experience</li>
                <li>Facilitating the recruitment process</li>
                <li>Complying with legal obligations</li>
              </ul>

              <h2>3. Legal Basis</h2>
              <p>Our processing of your personal data is based on:</p>
              <ul>
                <li>Your consent</li>
                <li>The necessity to perform a contract</li>
                <li>Compliance with legal obligations</li>
                <li>Legitimate interests pursued by our company</li>
              </ul>

              <h2>4. Data Retention</h2>
              <p>
                We retain your personal data only for as long as necessary for
                the purposes stated in this policy or as required by law. When
                your data is no longer needed, it will be securely deleted or
                anonymised.
              </p>

              <h2>5. Data Security</h2>
              <p>
                We implement appropriate technical and organisational measures
                to ensure the security and confidentiality of your personal data
                to prevent unauthorised access, disclosure, alteration, or
                destruction.
              </p>

              <h2>6. Sharing of Information</h2>
              <p>Your personal data may be shared with:</p>
              <ul>
                <li>Prospective employers for recruitment purposes</li>
                <li>
                  Third-party service providers involved in the recruitment
                  process
                </li>
                <li>Legal or regulatory authorities if required by law</li>
              </ul>

              <h2>7. Your Rights</h2>
              <p>You have the following rights regarding your personal data:</p>
              <ul>
                <li>
                  <strong>Access:</strong> Request access to your personal
                  information.
                </li>
                <li>
                  <strong>Rectification:</strong> Request correction of any
                  incomplete or inaccurate data.
                </li>
                <li>
                  <strong>Erasure:</strong> Request deletion of your personal
                  data under certain circumstances.
                </li>
                <li>
                  <strong>Objection:</strong> Object to the processing of your
                  data in specific situations.
                </li>
                <li>
                  <strong>Portability:</strong> Request the transfer of your
                  data to another organisation.
                </li>
                <li>
                  <strong>Withdraw Consent:</strong> Withdraw any consent you
                  have provided.
                </li>
              </ul>

              <h2>8. Cookie Policy</h2>
              <p>
                We use cookies to improve your experience and to track website
                analytics and performance. The cookies we use include:
              </p>
              <ul>
                <li>
                  <strong>Google Analytics:</strong> To collect information
                  about website usage and performance.
                </li>
                <li>
                  <strong>Google Tag Manager:</strong> To manage and deploy
                  marketing tags on our website.
                </li>
                <li>
                  <strong>Hotjar:</strong> To understand user behavior and
                  improve website usability.
                </li>
              </ul>
              <p>
                These cookies help us ensure the best possible performance of
                our website. You can manage your cookie preferences through your
                browser settings or by using our cookie consent banner.
              </p>

              <h2>9. Contact Us</h2>
              <p>
                If you have any questions, concerns, or requests regarding your
                personal data or this Privacy Policy, please contact us at{' '}
                <a href="mailto:privacy@ninedots.io">privacy@ninedots.io</a>.
              </p>

              <h2>10. Changes to this Policy</h2>
              <p>
                We reserve the right to update or change this Privacy Policy
                from time to time. Any updates will be reflected on this page.
              </p>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
}
