import React from 'react';
import PortableText from '@sanity/block-content-to-react';
import serializers from '../../utilities/serializers';

export default function Values({ summary, _rawValues }) {
  return (
    <>
      <section className="values">
        <div className="wrapper centered">
          {summary && (
            <div className="summary">
              <p>{summary}</p>
            </div>
          )}

          <div className="value-content">
            <h2>About Us</h2>
            <PortableText blocks={_rawValues} serializers={serializers} />
          </div>
        </div>
      </section>
    </>
  );
}
