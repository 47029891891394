import React from 'react';

export default function IconTick({ fill = 'black' }) {
  return (
    <svg
      width="15"
      viewBox="0 0 723 729"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.57784 126.792C3.78618 123.469 2.58826 119.771 2.06743 115.849C-0.359656 98.0367 12.1456 81.6044 29.9528 79.1721C130.234 65.62 273.406 21.5106 406.984 5.75543C502.745 -5.52582 593.802 -1.90082 663.651 32.7554C665.552 33.6877 691.614 50.8648 697.14 57.5523C703.411 65.1721 704.828 72.5627 705.166 77.3075C711.323 168.505 701.807 262.058 709.942 354.526C710.469 360.203 720.807 474.292 721.515 484.526C727.526 573.672 712.406 622.594 684.817 651.125C666.562 670.063 642.182 681.49 610.901 687.875C573.901 695.417 525.515 695.943 466.047 700.495C400.75 705.5 401.942 705.912 335.677 712.224C251.526 720.177 152.146 739.224 68.2601 721.563C53.9997 718.573 46.1924 710.021 42.5362 697.922C41.0049 692.917 42.3122 676.933 42.3122 676.933C42.3122 676.933 41.9737 680.183 42.0153 681.563C40.8174 632.526 40.0726 583.495 37.307 534.532C29.9893 405.13 13.1195 276.026 0.833052 147.031C0.124719 139.677 1.95284 132.615 5.57784 126.792ZM65.531 139.599C65.6091 140.011 65.6456 140.422 65.682 140.87C78.0414 270.683 94.9893 400.646 102.343 530.87C104.807 574.49 105.703 618.151 106.713 661.808C178.692 670.209 259.739 653.964 329.515 647.318C396.38 640.969 395.187 640.558 461.078 635.516C508.531 631.891 548.625 631.146 581.182 626.776C605.599 623.526 625.01 619.271 637.963 605.865C657.12 586.032 660.666 550.776 656.521 488.896C655.812 478.776 645.541 365.839 645.057 360.24C637.291 272.068 645.432 182.886 640.989 95.6825L633.521 90.5627C573.828 61.4377 496.099 60.8752 414.599 70.4742C292.932 84.8127 163.271 122.834 65.531 139.599Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M330.229 441.729C332.318 439.412 334.37 436.833 336.464 434.26C351.844 415.214 364.422 390.865 373.646 376.188C424.011 295.745 475.229 216.87 531.938 140.76C542.693 126.344 563.11 123.354 577.521 134.109C591.932 144.865 594.922 165.297 584.167 179.708C528.505 254.401 478.292 331.818 428.823 410.771C418.594 427.089 404.261 453.979 387.198 475.151C371.854 494.198 353.896 508.651 335.49 514.255C320.557 518.807 292.854 496.927 292.854 496.927C292.854 496.927 294.162 498.458 294.797 499.094C282.776 487.401 280.912 484.974 269.188 468.88C242.495 432.203 223.12 392.172 205.755 350.453C198.849 333.839 206.729 314.75 223.344 307.844C239.917 300.932 258.995 308.813 265.901 325.432C281.281 362.406 298.193 398.031 321.865 430.526C325.672 435.755 328.026 438.964 330.229 441.729Z"
        fill={fill}
      />
    </svg>
  );
}
