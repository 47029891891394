import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import SingleService from './SingleService';

export default function ServicesList() {
  const data = useStaticQuery(graphql`
    {
      sanityServicespage {
        allServices {
          service {
            heading
            subheading
            excerpt
            sectionId
            _rawBody
            img {
              asset {
                gatsbyImageData
              }
            }
            video
          }
        }
      }
    }
  `);

  const services = data.sanityServicespage.allServices.service;

  const serializers = {
    marks: {
      /* eslint-disable */
      link: ({ children, mark }) => (
        <a href={mark.href} target="_blank" rel="noreferrer">
          {children}
        </a>
      ),
      /* eslint-enable */
    },
  };

  return (
    <>
      <div id="services">
        <div className="wrapper centered">
          <div className="all-services">
            {services.map((service, i) => {
              const {
                sectionId,
                img,
                heading,
                subheading,
                excerpt,
                _rawBody,
                video,
              } = service;
              return (
                <SingleService
                  id={sectionId}
                  img={img?.asset.gatsbyImageData}
                  heading={heading}
                  subheading={subheading}
                  excerpt={excerpt}
                  body={_rawBody}
                  serializers={serializers}
                  key={i}
                  video={video}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
