import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import ServicesList from '../components/services/ServicesList';
import Pricing from '../components/services/Pricing';
import CtaBox from '../components/CtaBox';
import AITeams from '../components/services/AITeams';

import '../css/home/homepage.scss';
import GraySection from '../components/GraySection';

import '../css/services/servicespage.scss';

export default function ServicesPage({ data, location }) {
  const { title, heroText, heroImg } = data.sanityServicespage.heroSection;
  const { seo } = data.sanityServicespage;
  return (
    <>
      <SEO
        title={seo.title || title}
        description={seo.description && seo.description}
        image={seo.ogImage && seo.ogImage.asset.url}
        location={location}
      />
      <Layout>
        <Hero
          tag="Services"
          title={title}
          para={heroText}
          heroImg={heroImg.asset.url}
        />
        <GraySection>
          <ServicesList />
          <Pricing />
        </GraySection>
        <AITeams />
        <CtaBox />
      </Layout>
    </>
  );
}

export const query = graphql`
  {
    sanityServicespage {
      heroSection {
        title
        heroText
        heroImg {
          asset {
            gatsbyImageData
            url
          }
        }
      }
      seo {
        title
        description
        ogImage {
          asset {
            url
          }
        }
      }
    }
  }
`;
