import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import IconTick from '../../icons/IconTick';

export default function Principles() {
  const data = useStaticQuery(graphql`
    {
      sanityAboutpage {
        operatingPrinciples {
          heading
          whoBullets {
            title
            text
          }
          howBullets {
            title
            text
          }
        }
      }
    }
  `);
  const { heading, whoBullets, howBullets } =
    data.sanityAboutpage.operatingPrinciples;

  return (
    <section className="principles-section">
      <div className="wrapper centered">
        <div className="heading">
          <h2>{heading}</h2>
        </div>
      </div>

      <div className="principles">
        <div className="principle-box">
          <h3 className="title">How we work?</h3>
          <ul className="bullets">
            {howBullets.map((bullet) => (
              <li className="bullet">
                <div className="img">
                  <IconTick />
                </div>
                <div>
                  <p>
                    <strong>{bullet.title}</strong>
                  </p>
                  <p>{bullet.text}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="principle-box">
          <h3 className="title">Who are we?</h3>
          <ul className="bullets">
            {whoBullets.map((bullet) => (
              <li className="bullet">
                <div className="img">
                  <IconTick />
                </div>
                <div>
                  <p>
                    <strong>{bullet.title}</strong>
                  </p>
                  <p>{bullet.text}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}
