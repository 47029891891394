import React from 'react';

export default function VideoSection({ video }) {
  return (
    <div className="video-container">
      <video playsinline autoplay muted loop>
        <source src={`${video}.webm`} type="video/webm" />
        <source src={`${video}.mp4`} type="video/mp4" />
        <track kind="captions" srcLang="en" label="English" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}
