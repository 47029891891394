import React from 'react';

export default function GraySection({ children, type2, navTag, id }) {
  return (
    <section
      id={id}
      className={type2 ? 'gray-bg gray-bg-2' : 'gray-bg gray-bg-1'}
      data-nav-tag={navTag}
    >
      {children}
    </section>
  );
}
