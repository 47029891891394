import React, { useEffect, useState, useRef } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from '@sanity/block-content-to-react';
import VideoSection from '../VideoSection';

export default function SingleService({
  id,
  img,
  heading,
  subheading,
  excerpt,
  body,
  serializers,
  video,
}) {
  const accordionContent = useRef(null);
  const [openAccordion, setOpenServiceAccordion] = useState(false);

  useEffect(() => {
    const content = accordionContent.current;
    if (openAccordion) {
      content.style.maxHeight = `${content.scrollHeight}px`;
    } else {
      content.style.maxHeight = null;
    }
  });

  return (
    <>
      <div id={id} className={openAccordion ? 'service active' : 'service'}>
        <div className="service-info accordion">
          <div className="img">
            <GatsbyImage image={img} alt={heading} className="pet-pic" />
          </div>
          <div className="body">
            <h2>{heading}</h2>
            <h3>{subheading}</h3>
            <p className="occupation">{excerpt}</p>
            <div className="buttons">
              <button
                type="button"
                className="details-btn small"
                onClick={() => setOpenServiceAccordion(!openAccordion)}
              >
                {openAccordion ? '- Show less' : '+ Show more'}
              </button>
            </div>
          </div>
        </div>

        <div className="accordion-content" ref={accordionContent}>
          {body && <PortableText blocks={body} serializers={serializers} />}
          <div className="services-video">
            {video && <VideoSection video={video} />}
          </div>
        </div>
      </div>
    </>
  );
}
