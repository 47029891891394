import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Hero from '../Hero';
// import aiHero from '../../images/ai-hero.svg';

export default function AITeams() {
  const data = useStaticQuery(graphql`
    {
      sanityServicespage {
        aiTeams {
          heading
          subheading
          forCandidates
          forCompanys
          techHeading
          techSubheading
          technologies {
            title
            text
          }
          img {
            asset {
              url
              gatsbyImage
            }
          }
        }
      }
    }
  `);

  const { aiTeams } = data.sanityServicespage;

  const aiTech = aiTeams.technologies;

  return (
    <div id="ai-teams">
      <Hero
        tag=""
        title={aiTeams.heading}
        para={aiTeams.subheading}
        heroImg={aiTeams.img.asset.url}
      />
      <div className="wrapper">
        <div className="technologies">
          <div className="header ">
            <h2>{aiTeams.techHeading}</h2>
            <p>{aiTeams.techSubheading}</p>
          </div>
          <ul className="grid">
            {aiTech.map((tech) => (
              <li className="item">
                <h3>{tech.title}</h3>
                <p>{tech.text}</p>
              </li>
            ))}
          </ul>
        </div>
        <div className="split">
          <div>
            <div className="col">
              <h2>For Companys</h2>
              <ul>
                {aiTeams.forCompanys.map((bullet) => (
                  <li>{bullet}</li>
                ))}
              </ul>
            </div>
          </div>
          <div>
            <div className="col">
              <h2>For Candidates</h2>
              <ul>
                {aiTeams.forCandidates.map((bullet) => (
                  <li>{bullet}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
