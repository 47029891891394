import React, { useRef, useState, useEffect, useMemo } from 'react';
import rightArrow from '../images/right-arrow.svg';

const video =
  'https://res.cloudinary.com/de6m3kn4q/video/upload/f_auto:video,q_auto/v1/nineDots/process-final';

export default function InteractiveProcess() {
  const videoRef = useRef(null);
  const [currentBreakpoint, setCurrentBreakpoint] = useState(0);
  const [isPausedAtBreakpoint, setIsPausedAtBreakpoint] = useState(false);

  const breakpoints = useMemo(() => [0, 5, 10, 16, 23, 29, 34, 40], []);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (!videoElement) return;

    const handleTimeUpdate = () => {
      if (
        currentBreakpoint < breakpoints.length &&
        videoElement.currentTime >= breakpoints[currentBreakpoint]
      ) {
        videoElement.pause();
        setIsPausedAtBreakpoint(true);
      }
    };

    // Attach the timeupdate listener
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      // Clean up listener
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [currentBreakpoint, breakpoints]);

  const handleContinue = () => {
    if (currentBreakpoint < breakpoints.length) {
      setCurrentBreakpoint((prev) => prev + 1);
      setIsPausedAtBreakpoint(false);
      if (videoRef.current) videoRef.current.play();
    }
  };

  const handleReset = () => {
    setCurrentBreakpoint(0);
    setIsPausedAtBreakpoint(false);
    if (videoRef.current) {
      videoRef.current.currentTime = 0; // Reset video to the beginning
      videoRef.current.play(); // Optionally auto-play the video after reset
    }
  };

  const handleVideoEnded = () => {
    handleReset(); // Reset the video when it ends
  };

  return (
    <div className="video-container interactive wrapper centered">
      <video
        ref={videoRef}
        playsInline
        autoPlay
        muted
        loop
        onEnded={handleVideoEnded} // Reset the video when it ends
      >
        <source src={`${video}.webm`} type="video/webm" />
        <source src={`${video}.mp4`} type="video/mp4" />
        <track kind="captions" srcLang="en" label="English" />
        Your browser does not support the video tag.
      </video>

      <button
        className={
          isPausedAtBreakpoint ? 'interactive-btn active' : 'interactive-btn'
        }
        onClick={handleContinue}
        type="button"
      >
        <img src={rightArrow} alt="right arrow" />
      </button>
    </div>
  );
}
