import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

import '../css/about/aboutpage.scss';

export default function PrivacyPolicyPage() {
  return (
    <>
      <SEO
        title="Privacy Policy"
        description="Ninedots privacy and cookie policies"
      />

      <Layout>
        <section className="blog-hero">
          <div className="wrapper centered">
            <div className="heading-content">
              <h1>Terms &amp; Conditions</h1>

              <p className="summary">
                Welcome to <strong>nineDots.io</strong>. These Terms and
                Conditions govern your use of our website and the services we
                provide. By accessing or using our website and services, you
                agree to be bound by these Terms. If you do not agree with these
                Terms, please do not use our website.
              </p>
            </div>
          </div>
        </section>

        <section className="blog-template">
          <div className="wrapper centered">
            <div className="template-content">
              <body>
                <h2>1. Introduction</h2>
                <p>
                  These Terms and Conditions are applicable to all users of our
                  website, including job seekers, employers, and other visitors.
                  By using our website, you agree to comply with these Terms and
                  all applicable laws and regulations.
                </p>

                <h2>2. Use of Services</h2>
                <p>
                  Our website provides recruitment services, including job
                  postings, candidate profiles, and other resources for
                  employers and job seekers. You may only use our services for
                  lawful purposes and in a manner that does not infringe the
                  rights of others.
                </p>
                <p>
                  We reserve the right to refuse or suspend your access to our
                  website or services at our discretion, including for violation
                  of these Terms.
                </p>

                <h2>3. Account Registration</h2>
                <p>
                  To access certain features of our website, you may be required
                  to create an account or upload personal information. When
                  registering, you agree to provide accurate, current, and
                  complete information and keep your account details up to date.
                </p>
                <p>
                  You are responsible for maintaining the confidentiality of
                  your account and password. Any activities conducted under your
                  account are your responsibility.
                </p>

                <h2>4. Privacy and Data Protection</h2>
                <p>
                  Your use of our services is subject to our{' '}
                  <a href="/privacy-policy">Privacy Policy</a>, which outlines
                  how we collect, use, and protect your personal information. By
                  using our services, you consent to our Privacy Policy.
                </p>

                <h2>5. Intellectual Property</h2>
                <p>
                  The content on our website, including text, graphics, logos,
                  images, and software, is owned by <strong>nineDots.io</strong>{' '}
                  or its licensors and is protected by copyright, trademark, and
                  other intellectual property laws.
                </p>
                <p>
                  You may not reproduce, distribute, or modify any content on
                  our website without our prior written consent, except as
                  allowed by applicable law.
                </p>

                <h2>6. Restrictions</h2>
                <p>You agree not to:</p>
                <ul>
                  <li>
                    Engage in any unlawful activities or activities that
                    infringe upon the rights of others.
                  </li>
                  <li>
                    Access or attempt to access restricted areas of our website
                    without authorization.
                  </li>
                  <li>
                    Interfere with or disrupt the operation of our website or
                    services.
                  </li>
                  <li>
                    Upload, post, or transmit any harmful or malicious content,
                    including viruses, malware, or other harmful materials.
                  </li>
                </ul>

                <h2>7. Third-Party Services</h2>
                <p>
                  Our website may include links to third-party websites or
                  services, including recruitment platforms or other resources.
                  These links are provided for your convenience, but we do not
                  endorse or control these third-party services, and we are not
                  responsible for their content, privacy practices, or policies.
                </p>

                <h2>8. Disclaimers</h2>
                <p>
                  Our website and services are provided "as is" without any
                  warranties, express or implied. We do not guarantee the
                  accuracy, completeness, or reliability of any content or
                  materials on our website. We also do not guarantee the
                  availability, performance, or uptime of our services.
                </p>
                <p>
                  We disclaim all liability for any damages resulting from the
                  use of our website, including indirect, incidental, or
                  consequential damages, even if we have been advised of the
                  possibility of such damages.
                </p>

                <h2>9. Limitation of Liability</h2>
                <p>
                  To the fullest extent permitted by law,{' '}
                  <strong>nineDots.io</strong> will not be liable for any loss,
                  damage, or expense arising out of or in connection with the
                  use of our website or services, including but not limited to
                  lost profits, loss of data, or any other intangible losses.
                </p>

                <h2>10. Indemnity</h2>
                <p>
                  You agree to indemnify and hold harmless{' '}
                  <strong>nineDots.io</strong> and its officers, directors,
                  employees, agents, and affiliates from any claims,
                  liabilities, damages, losses, or expenses arising from your
                  use of our website, violation of these Terms, or infringement
                  of the rights of any third party.
                </p>

                <h2>11. Governing Law</h2>
                <p>
                  These Terms and Conditions are governed by and construed in
                  accordance with the laws of the jurisdiction in which{' '}
                  <strong>nineDots.io</strong> operates, without regard to its
                  conflict of law provisions.
                </p>

                <h2>12. Changes to Terms</h2>
                <p>
                  We reserve the right to modify or update these Terms at any
                  time. Any changes will be reflected on this page, and the
                  "Last Updated" date will be revised accordingly. We encourage
                  you to review these Terms regularly to stay informed about any
                  changes.
                </p>

                <h2>13. Termination</h2>
                <p>
                  We may suspend or terminate your access to our website and
                  services at any time, without notice, for any reason,
                  including violations of these Terms.
                </p>

                <h2>14. Contact Us</h2>
                <p>
                  If you have any questions, comments, or concerns about these
                  Terms and Conditions, please contact us at:
                </p>
                <p>
                  Email:
                  <a href="mailto:privacy@ninedots.io">privacy@ninedots.io</a>
                </p>
              </body>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
}
