import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { PopupButton } from '@typeform/embed-react';
import IconTick from '../../icons/IconTick';

export default function Pricing() {
  const data = useStaticQuery(graphql`
    {
      sanityServicespage {
        pricing {
          heading
          subheading
          priceTier {
            title
            tab
            rate
            rateTerms
            bullets
            Featured
          }
          tag
        }
      }
    }
  `);
  const { pricing } = data.sanityServicespage;
  const pricingTiers = pricing.priceTier;

  return (
    <>
      <div id="pricing" className="pricing">
        <div className="wrapper centered">
          <div className="heading">
            <span className="tag">{pricing.tag}</span>
            <h2>{pricing.heading}</h2>
            <p className="exerpt">{pricing.subheading}</p>
          </div>
        </div>

        <div className="pricing-tiers">
          {pricingTiers.map((tier) => (
            <div
              className={`tier-container ${tier.Featured ? 'featured' : ''}`}
            >
              <div className="tab-container">
                <div className="tab">
                  {tier.tab} <span className="star">&#11088;</span>
                </div>
              </div>
              <div className="tier">
                <div className="break">
                  <span />
                </div>
                <div className="body">
                  <h3 className="header">
                    <span className="title">{tier.title}</span>
                    <span className="rate">{tier.rate}</span>
                    <span className="rate-terms">{tier?.rateTerms}</span>
                  </h3>
                  <ul className="bullets">
                    {tier.bullets.map((bullet) => (
                      <li className="bullet">
                        <span
                          className={`img ${tier.Featured ? 'featured' : ''}`}
                        >
                          <IconTick />
                        </span>
                        <span>{bullet}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="footer">
                  <PopupButton id="uvQcDWjQ" className="button">
                    Get Started
                  </PopupButton>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
