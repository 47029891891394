import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Logo from '../../images/logo-wide-OPT.png';
import UpArrow from '../../images/up-arrow.svg';

export default function StatsSection() {
  const data = useStaticQuery(graphql`
    {
      sanityHomepage {
        stats {
          heading
          rows {
            title
            ninedots
            peeravg
            percentage
          }
        }
      }
    }
  `);
  const { heading, rows } = data.sanityHomepage.stats;

  return (
    <>
      <div className="stats">
        <div className="wrapper centered">
          <div className="heading">
            <h2>{heading}</h2>
          </div>
          <div className="stats-grid">
            <div className="stats-row titles">
              <div></div>
              <span className="value">
                <img
                  className="logo-stats invert-color"
                  src={Logo}
                  alt="ninedots logo flat"
                  objectFit="contain"
                />
              </span>
              <span className="value">Peer Average</span>
              <span></span>
            </div>
            {rows.map((row, index) => (
              <div className="stats-row" key={index}>
                <div className="row-title">{row.title}</div>
                <span className="value mobile">{row.ninedots}</span>
                <span className="value">{row.peeravg}</span>
                <div className="value percent">
                  {' '}
                  <img
                    className="up-arrow invert-color"
                    src={UpArrow}
                    alt="Up Arrow"
                  />
                  <span>{row.percentage}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
